import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import GetIcon from '../utils/getIcon';
import Nav from './nav/nav';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Hotkeys from 'react-hot-keys';

const orangeBorderTopHeight = 5;
const appBarHeight = 60;
const drawerWidth = 300;
const orangeBorder = `${orangeBorderTopHeight}px solid `;

const styles = (theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        opacity: `0.95`
    },
    drawerPaper: {
        borderTop: orangeBorder + theme.palette.primary.main,
        width: drawerWidth,
        backgroundColor: theme.palette.secondary.main,
        borderRight: `1px solid #000`
    },
    drawerHeader: {
        borderTop: `1px solid #000`,
        borderBottom: `1px solid #000`,
        display: `flex`,
        minHeight: `${appBarHeight}px`,
        height: `${appBarHeight}px`,
        alignItems: `center`,
        padding: `0 8px`,
        justifyContent: `space-between`,
        color: `#FFF`
    },
    drawerHeading: {
        textTransform: `uppercase`,
        fontSize: `24px`,
        paddingLeft: `.5em`,
        color: `#FFF`,
        fontWeight: 700
    },
    icon: {
        color: `#FFF`
    }
});

class MenuDrawer extends React.Component {
    state = {
        navOpen: this.props.open
    };

    handleDrawerOpen = () => {
        this.setState({ navOpen: true });
    };

    handleDrawerClose = () => {
        this.setState({ navOpen: false });
    };

    openDrawerWithAltM = (keyName, event, handle) => {
        if (this.state.navOpen === false) {
            this.handleDrawerOpen();
            return;
        }
        this.handleDrawerClose();
    };

    render() {
        const { classes } = this.props;
        const { navOpen } = this.state;

        return (
            <>
                <Hotkeys
                    keyName="alt+m,⌥+m"
                    onKeyDown={this.openDrawerWithAltM}
                />
                <Drawer
                    className={classes.drawer}
                    anchor="left"
                    open={navOpen}
                    onClose={this.handleDrawerClose}
                    classes={{
                        paper: classes.drawerPaper
                    }}
                >
                    <div
                        className={classNames(
                            classes.drawerHeader,
                            `text-shadow`
                        )}
                    >
                        <span
                            className={classNames(
                                classes.drawerHeading,
                                `montserrat`
                            )}
                        >
                            Menu
                        </span>
                        <IconButton onClick={this.handleDrawerClose}>
                            <FontAwesomeIcon
                                className={classNames(
                                    classes.icon,
                                    `text-shadow-double`
                                )}
                                icon={GetIcon(`times-circle`)}
                            />
                        </IconButton>
                    </div>
                    <Divider />
                    <Nav />
                </Drawer>
            </>
        );
    }
}

MenuDrawer.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(MenuDrawer);
