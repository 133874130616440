import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import LinkItem from './link-item';

class StartHere extends React.Component {
    getPages = (data) => {
        return data.prismic.page_list.pages.map((linkedItem) => (
            <LinkItem
                text={RichText.asText(linkedItem.page.name)}
                urlPath={`/${linkedItem.page._meta.uid}`}
                key={linkedItem.page._meta.uid}
            />
        ));
    };

    render() {
        return (
            <StaticQuery
                query={`${startHereQuery}`}
                render={(data) => <>{this.getPages(data)}</>}
            />
        );
    }
}

export default StartHere;

const startHereQuery = graphql`
    query StartHereQuery {
        prismic {
            page_list(uid: "start-here", lang: "en-gb") {
                pages {
                    page {
                        __typename
                        ... on PRISMIC_Page {
                            name
                            _meta {
                                uid
                            }
                        }
                        __typename
                        ... on PRISMIC_Quest {
                            name
                            _meta {
                                uid
                            }
                        }
                        __typename
                        ... on PRISMIC_Realm {
                            name
                            _meta {
                                uid
                            }
                        }
                    }
                }
            }
        }
    }
`;
