import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import AppShell from './app-shell';
import '../styles/global.css';

class Layout extends React.Component {
    render() {
        const { children, noHeader } = this.props;

        return (
            <StaticQuery
                query={`${siteTitleQuery}`}
                render={(data) => (
                    <AppShell noHeader={noHeader}>{children}</AppShell>
                )}
            />
        );
    }
}

Layout.propTypes = {
    children: PropTypes.node.isRequired,
    noHeader: PropTypes.bool
};

export default Layout;

const siteTitleQuery = graphql`
    query SiteTitleQuery {
        site {
            siteMetadata {
                title
            }
        }
    }
`;
