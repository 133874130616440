import React from 'react';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';

class LinkItem extends React.Component {
    render() {
        const { text, urlPath, bold } = this.props;
        return (
            <Link color={`inherit`} href={urlPath} className={`text-shadow`}>
                {bold && <strong>{text}</strong>}
                {!bold && <>{text}</>}
            </Link>
        );
    }
}

LinkItem.propTypes = {
    text: PropTypes.string.isRequired,
    urlPath: PropTypes.string.isRequired
};

export default LinkItem;
