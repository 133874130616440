import React from 'react';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fisheyeLogo from '../images/fisheye-logo.png';
import GetIcon from '../utils/getIcon';
import Paper from '@material-ui/core/Paper';

const mailchimpUrl = `//magequest.us18.list-manage.com/subscribe/post?u=30fdd16ba0e7fb62c8ad8e258&amp;id=7d0e67e523`;

const boxShadowBottom = `0px -1px 4px 0px rgba(50,50,50,0.2), 0px 0px 5px 0px rgba(50,50,50,0.14), 0px 0px 10px 0px rgba(50,50,50,0.12)`;

const styles = (theme) => ({
    wrapper: {
        borderRadius: 0,
        marginTop: 0,
        padding: `30px 30px 110px`,
        backgroundColor: theme.palette.secondary.light,
        opacity: 0.97,
        boxShadow: boxShadowBottom,
        [theme.breakpoints.up(`sm`)]: {
            padding: `30px 30px 90px`
        },
        borderTop: `1px solid #000`,
        borderBottom: `1px solid #000`,
        zIndex: 10
    },
    footer: {
        color: `#FFF`,
        display: `flex`,
        flexDirection: `column`,
        [theme.breakpoints.up('md')]: {
            flexDirection: `row`,
            alignItems: `top`,
            justifyContent: `space-between`
        }
    },
    footerText: {},
    copyrightContainer: {
        order: 3,
        marginTop: 20,
        marginBottom: 10,
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            marginBottom: 0,
            order: 1
        }
    },
    copyrightTwitterContainer: {
        display: `flex`,
        alignItems: `center`,
        justifyContent: `center`,
        [theme.breakpoints.up('md')]: {
            justifyContent: `left`
        }
    },
    copyright: {
        textTransform: `uppercase`,
        fontSize: `20px`,
        fontWeight: 500,
        textAlign: `center`,
        marginRight: `5px`
    },
    copyrightYear: {
        color: theme.palette.primary.main
    },
    icons: {
        display: `flex`,
        justifyContent: `center`,
        [theme.breakpoints.up('md')]: {
            display: `block`
        }
    },
    iconLink: {
        color: `#FFF`,
        marginTop: 10,
        [theme.breakpoints.up('md')]: {
            marginTop: 0,
            marginLeft: -12
        }
    },
    theiaAndBugLink: {
        color: `#FFF`,
        display: `block`,
        textTransform: `uppercase`,
        fontSize: `14px`,
        fontWeight: 500,
        textAlign: `center`,
        paddingTop: 4,
        [theme.breakpoints.up('md')]: {
            textAlign: `left`
        },
        '&:hover': {
            textDecoration: `none`
        }
    },
    fisheyeLink: {
        display: `flex`,
        flexDirection: `column`,
        justifyContent: `center`,
        alignItems: `center`,
        margin: `30px 0 20px 0`,
        order: 2,
        '&:hover': {
            textDecoration: `none`
        },
        [theme.breakpoints.up('md')]: {
            marginTop: -16,
            order: 2
        }
    },
    fisheyeLinkText: {
        marginBottom: 13,
        color: `#FFF`,
        textTransform: `uppercase`,
        fontSize: `20px`,
        fontWeight: 500
    },
    newsletterContainer: {
        order: 1,
        [theme.breakpoints.up('md')]: {
            order: 3
        },
        marginRight: `10px`
    },
    newsletterTitle: {
        color: `#FFF`,
        textAlign: `center`,
        paddingBottom: 0,
        textTransform: `uppercase`,
        fontSize: `20px`,
        fontWeight: 500,
        display: `block`
    },
    mailchimp: {
        display: `flex`,
        margin: 0,
        alignItems: `flex-start`,
        justifyContent: `center`,
        [theme.breakpoints.up('md')]: {
            justifyContent: `space-between`
        },
        '& #mailchimp': {
            width: `191px`
        },
        '& input': {
            backgroundColor: theme.palette.primary.main,
            color: `#FFF`,
            border: `1px solid #000`,
            borderRadius: `3px`,
            height: `20px`,
            lineHeight: `30px`,
            fontSize: `16px`,
            padding: `18px 10px`,
            margin: `8px 0 0`,
            fontFamily: `montserrat`
        },
        '& input::placeholder': {
            color: `#FFF`,
            textTransform: `uppercase`,
            textShadow: `-1px 0 #000, 0 1px #000, 1px 0 #000, 0 -1px #000`,
            fontWeight: 600
        },
        '& div > button': {
            display: `none`
        },
        '& div > div': {
            display: `flex`,
            flexDirection: `column-reverse`
        },
        '& div > div > div': {
            fontSize: `14px`,
            marginTop: `5px`
        }
    },
    submitIcon: {
        color: `#FFF`
    }
});

class Footer extends React.Component {
    getYear = () => {
        return new Date().getFullYear();
    };

    openTwitter = () => {
        window.open(`https://twitter.com/magequestio`, `_blank`);
    };

    openGitHub = () => {
        window.open(`https://github.com/MageQuest/magequest`, `_blank`);
    };

    submitMailchimpForm = () => {
        let mailchimpContainer = document.getElementById(`mailchimp`);
        let submitButton = mailchimpContainer.getElementsByTagName(`button`);
        submitButton[0].click();
    };

    render() {
        const { classes } = this.props;
        return (
            <Paper className={classes.wrapper}>
                <footer className={classes.footer}>
                    <div className={classes.copyrightContainer}>
                        <div className={classes.copyrightTwitterContainer}>
                            <div
                                className={classNames(
                                    classes.copyright,
                                    `text-shadow`,
                                    `montserrat`
                                )}
                            >
                                &copy;{` `}
                                <span
                                    className={classNames(
                                        classes.copyrightYear,
                                        `montserrat`
                                    )}
                                >
                                    {this.getYear()}
                                </span>
                                {` `}
                                MageQuest
                            </div>
                        </div>
                        <Link
                            href={`https://www.theiaandbug.co.uk/`}
                            target={`_blank`}
                            className={classes.theiaAndBugLink}
                        >
                            <span
                                className={classNames(
                                    `text-shadow`,
                                    `montserrat`
                                )}
                            >
                                Logo design by Theia &amp; Bug
                            </span>
                        </Link>
                        <div className={classes.icons}>
                            <IconButton
                                aria-label={`twitter`}
                                className={classNames(
                                    classes.iconLink,
                                    `text-shadow-double`
                                )}
                                onClick={this.openTwitter}
                            >
                                <FontAwesomeIcon icon={GetIcon(`twitter`)} />
                            </IconButton>
                            <IconButton
                                aria-label={`github`}
                                className={classNames(
                                    classes.iconLink,
                                    `text-shadow-double`
                                )}
                                onClick={this.openGitHub}
                            >
                                <FontAwesomeIcon icon={GetIcon(`github`)} />
                            </IconButton>
                        </div>
                    </div>
                    <Link
                        href={`https://www.fisheye.co.uk/`}
                        target={`_blank`}
                        className={classNames(
                            classes.fisheyeLink,
                            `montserrat`
                        )}
                    >
                        <div
                            className={classNames(
                                classes.fisheyeLinkText,
                                `text-shadow`,
                                `montserrat`
                            )}
                        >
                            Supported by{` `}
                            <span className={`visuallyhidden`}>Fisheye</span>
                        </div>
                        <img
                            src={fisheyeLogo}
                            alt={`Fisheye Media Ltd.`}
                            width={140}
                            height={33}
                        />
                    </Link>
                    <div className={classes.newsletterContainer}>
                        <span
                            className={classNames(
                                classes.newsletterTitle,
                                `text-shadow`,
                                `montserrat`
                            )}
                        >
                            Join our mailing list
                        </span>
                        <div
                            className={classNames(
                                classes.mailchimp,
                                `montserrat`
                            )}
                        >
                            <div id={`mailchimp`}>
                                <MailchimpSubscribe url={mailchimpUrl} />
                            </div>
                            <Tooltip title={`Submit`} placement={`right`}>
                                <IconButton
                                    aria-label={`submit`}
                                    className={classNames(
                                        classes.submitIcon,
                                        `text-shadow-double`
                                    )}
                                    onClick={this.submitMailchimpForm}
                                >
                                    <FontAwesomeIcon
                                        icon={GetIcon(`paper-plane`)}
                                    />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </div>
                </footer>
            </Paper>
        );
    }
}

export default withStyles(styles, { withTheme: true })(Footer);
