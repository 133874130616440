import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GetIcon from '../../utils/getIcon';
import StartHere from './start-here';
import AllRealms from './all-realms';
import About from './about';

const styles = (theme) => ({
    nav: {
        backgroundColor: theme.palette.secondary.main,
        color: `#FFF`,
        '& div': {
            fontFamily: `Montserrat`
        }
    },
    logo: {
        margin: `0 auto`,
        display: `block`
    },
    home: {
        backgroundColor: theme.palette.secondary.main,
        color: `#FFF`,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '.5em .6em',
        boxShadow: 'none'
    },
    panel: {
        backgroundColor: theme.palette.secondary.main,
        color: `#FFF`,
        boxShadow: 'none',
        '&:before': {
            opacity: 0
        },
        padding: '0 .6em'
    },
    summary: {
        padding: 0,
        width: `93%`,
        '& > div': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center'
        },
        '& > div:last-child': {
            color: `#FFF`,
            padding: '1em .2em',
            fontSize: '1.2em'
        }
    },
    heading: {
        color: `#FFF`,
        textTransform: 'uppercase',
        fontSize: '18px',
        lineHeight: '18px',
        fontWeight: 700,
        '&:hover': {
            textDecoration: 'none'
        }
    },
    icon: {
        color: `#FFF`,
        minWidth: '1em',
        maxWidth: '1em',
        position: `relative`,
        top: -1
    },
    realmIcon: {
        minWidth: '.9em',
        maxWidth: '.9em',
        top: -2
    },
    expandIcon: {
        fontSize: 20
    },
    details: {
        padding: `0 24px 24px`,
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
            display: 'flex',
            width: '100%',
            justifyItems: 'flexStart',
            alignItems: 'center'
        },
        '& a': {
            marginBottom: '16px',
            textTransform: 'uppercase',
            fontWeight: 500,
            fontSize: `16px`
        },
        '& a:hover': {
            textDecoration: `none`
        }
    },
    text: {
        marginBottom: '16px',
        textTransform: 'uppercase',
        fontWeight: 500,
        fontSize: `16px`
    },
    innerIcon: {
        marginLeft: '-.5em'
    }
});

class Nav extends React.Component {
    state = {
        expanded: null
    };

    handleChange = (panel) => (event, expanded) => {
        this.setState({
            expanded: expanded ? panel : false
        });
    };

    goHome = () => {
        window.location = '/';
    };

    render() {
        const { classes } = this.props;
        const { expanded } = this.state;

        return (
            <nav className={classes.nav}>
                <Paper onClick={this.goHome} className={classes.home}>
                    <IconButton>
                        <FontAwesomeIcon
                            className={classNames(
                                classes.icon,
                                `text-shadow-double`
                            )}
                            icon={GetIcon(`home`)}
                        />
                    </IconButton>
                    <Link
                        href="/"
                        className={classNames(classes.heading, `text-shadow`)}
                    >
                        Home
                    </Link>
                </Paper>
                <ExpansionPanel
                    expanded={expanded === 'start-here'}
                    onChange={this.handleChange('start-here')}
                    className={classes.panel}
                >
                    <ExpansionPanelSummary
                        className={classes.summary}
                        expandIcon={
                            <FontAwesomeIcon
                                className={classNames(
                                    classes.icon,
                                    classes.expandIcon,
                                    `text-shadow-double`
                                )}
                                icon={GetIcon(`chevron-down`)}
                            />
                        }
                    >
                        <IconButton>
                            <FontAwesomeIcon
                                className={classNames(
                                    classes.icon,
                                    `text-shadow-double`
                                )}
                                icon={GetIcon(`flag-checkered`)}
                            />
                        </IconButton>
                        <span
                            className={classNames(
                                classes.heading,
                                `text-shadow`
                            )}
                        >
                            Start Here
                        </span>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.details}>
                        <StartHere />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel
                    expanded={expanded === 'quests'}
                    onChange={this.handleChange('quests')}
                    className={classes.panel}
                >
                    <ExpansionPanelSummary
                        className={classes.summary}
                        expandIcon={
                            <FontAwesomeIcon
                                className={classNames(
                                    classes.icon,
                                    classes.expandIcon,
                                    `text-shadow-double`
                                )}
                                icon={GetIcon(`chevron-down`)}
                            />
                        }
                    >
                        <IconButton>
                            <FontAwesomeIcon
                                className={classNames(
                                    classes.icon,
                                    `text-shadow-double`
                                )}
                                icon={GetIcon(`map-marked-alt`)}
                            />
                        </IconButton>
                        <span
                            className={classNames(
                                classes.heading,
                                `text-shadow`
                            )}
                        >
                            Quests
                        </span>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.details}>
                        <div>
                            <IconButton className={classes.innerIcon}>
                                <FontAwesomeIcon
                                    className={classNames(
                                        classes.icon,
                                        classes.realmIcon,
                                        `text-shadow-double`
                                    )}
                                    icon={GetIcon(`fort-awesome`)}
                                />
                            </IconButton>
                            <span
                                className={classNames(
                                    classes.heading,
                                    `text-shadow`
                                )}
                            >
                                Realms
                            </span>
                        </div>
                        <AllRealms />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel
                    expanded={expanded === 'certification'}
                    onChange={this.handleChange('certification')}
                    className={classes.panel}
                >
                    <ExpansionPanelSummary
                        className={classes.summary}
                        expandIcon={
                            <FontAwesomeIcon
                                className={classNames(
                                    classes.icon,
                                    classes.expandIcon,
                                    `text-shadow-double`
                                )}
                                icon={GetIcon(`chevron-down`)}
                            />
                        }
                    >
                        <IconButton>
                            <FontAwesomeIcon
                                className={classNames(
                                    classes.icon,
                                    `text-shadow-double`
                                )}
                                icon={GetIcon(`scroll-old`)}
                            />
                        </IconButton>
                        <span
                            className={classNames(
                                classes.heading,
                                `text-shadow`
                            )}
                        >
                            Certification
                        </span>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.details}>
                        <span
                            className={classNames(classes.text, `text-shadow`)}
                        >
                            Coming in future!
                        </span>
                    </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel
                    expanded={expanded === 'about'}
                    onChange={this.handleChange('about')}
                    className={classes.panel}
                >
                    <ExpansionPanelSummary
                        className={classes.summary}
                        expandIcon={
                            <FontAwesomeIcon
                                className={classNames(
                                    classes.icon,
                                    classes.expandIcon,
                                    `text-shadow-double`
                                )}
                                icon={GetIcon(`chevron-down`)}
                            />
                        }
                    >
                        <IconButton>
                            <FontAwesomeIcon
                                className={classNames(
                                    classes.icon,
                                    `text-shadow-double`
                                )}
                                icon={GetIcon(`info-circle`)}
                            />
                        </IconButton>
                        <span
                            className={classNames(
                                classes.heading,
                                `text-shadow`
                            )}
                        >
                            About
                        </span>
                    </ExpansionPanelSummary>
                    <ExpansionPanelDetails className={classes.details}>
                        <About />
                    </ExpansionPanelDetails>
                </ExpansionPanel>
            </nav>
        );
    }
}

Nav.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Nav);
