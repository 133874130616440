import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import * as JsSearch from 'js-search';
import searchData from '../data/search';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Hotkeys from 'react-hot-keys';
import GetIcon from '../utils/getIcon';

const styles = (theme) => ({
    tooltipPopper: {
        display: `none`,
        [theme.breakpoints.up('md')]: {
            display: `block`
        }
    },
    iconOuter: {
        paddingRight: `5px`,
        [theme.breakpoints.up('md')]: {
            paddingRight: `12px`
        }
    },
    iconWrapper: {
        position: `relative`
    },
    icon: {
        color: theme.palette.secondary.main,
        minWidth: '1em',
        maxWidth: '1em',
        zIndex: 1000
    },
    iconText: {
        display: `none`,
        [theme.breakpoints.up('md')]: {
            display: `block`
        },
        position: `absolute`,
        top: 11,
        left: -94,
        fontSize: `22px`,
        fontWeight: 600,
        textTransform: 'uppercase',
        color: theme.palette.secondary.main,
        cursor: `pointer`,
        lineHeight: `1.25em`
    },
    searchWrapper: {
        position: `absolute`,
        top: 0,
        left: 0,
        width: `100%`,
        zIndex: 999
    },
    link: {
        display: 'flex',
        alignItems: 'center',
        fontWeight: 400,
        fontSize: `20px`,
        '&:hover': {
            textDecoration: `none`
        }
    },
    searchInput: {
        position: `relative`,
        width: `100%`,
        height: `61px`,
        top: `-1px`,
        border: `none`,
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: `#FFF`,
        padding: `0 20px`,
        textTransform: `uppercase`,
        outline: `none`,
        fontWeight: 600,
        fontSize: `16px`,
        [theme.breakpoints.up('sm')]: {
            padding: `0 5%`,
            fontSize: `18px`
        },
        [theme.breakpoints.up('md')]: {
            fontSize: `20px`
        },
        [theme.breakpoints.up(`lg`)]: {
            padding: `0 10%`
        },
        [theme.breakpoints.up(`xl`)]: {
            padding: `0 15%`
        },
        '&::-webkit-input-placeholder': {
            color: `#FFF`
        },
        borderBottom: `1px solid #000`
    },
    resultsContainer: {
        width: `100%`,
        padding: `20px 20px 0`,
        backgroundColor: theme.palette.secondary.main,
        borderBottom: `1px solid #000`,
        [theme.breakpoints.up('sm')]: {
            padding: `35px 35px 0`
        }
    },
    resultsInner: {
        [theme.breakpoints.up('md')]: {
            display: `flex`
        }
    },
    result: {
        marginBottom: `20px`,
        '&:last-child': {
            marginBottom: `30px`
        }
    },
    resultTitle: {
        fontSize: `24px`,
        fontWeight: 600,
        margin: `0 0 30px`,
        paddingBottom: 2,
        [theme.breakpoints.up('md')]: {
            width: `75%`
        },
        color: `#FFF`
    },
    resultIcon: {
        position: `relative`,
        marginRight: `12px`
    },
    resultText: {
        fontSize: `20px`,
        fontWeight: 500,
        textTransform: `uppercase`,
        lineHeight: 1.5,
        color: `#FFF`
    },
    resultTypeContainer: {
        flex: 1,
        [theme.breakpoints.up('md')]: {
            maxWidth: `50%`
        }
    },
    lightText: {
        color: `#FFF`
    }
});

class Search extends React.Component {
    state = {
        searchItems: searchData.items,
        search: [],
        searchResults: [],
        isLoading: true,
        isError: false,
        searchQuery: ``,
        showSearch: false
    };

    async componentDidMount() {
        this.rebuildIndex();
        document.addEventListener('mousedown', this.watchClickOutside, false);
        document.addEventListener('touchstart', this.watchClickOutside, false);
    }

    componentWillUnmount() {
        document.removeEventListener(
            'mousedown',
            this.watchClickOutside,
            false
        );
        document.removeEventListener(
            'touchstart',
            this.watchClickOutside,
            false
        );
    }

    componentDidUpdate() {
        this.searchInput.focus();
    }

    rebuildIndex = () => {
        const { searchItems } = this.state;
        const dataToSearch = new JsSearch.Search('url');

        dataToSearch.indexStrategy = new JsSearch.AllSubstringsIndexStrategy();
        dataToSearch.sanitizer = new JsSearch.LowerCaseSanitizer();
        dataToSearch.addIndex('name');
        dataToSearch.addIndex('searchTerms');
        dataToSearch.addDocuments(searchItems);
        this.setState({ search: dataToSearch, isLoading: false });
    };

    searchData = (event) => {
        const { search } = this.state;
        const queryResult = search.search(event.target.value);
        this.setState({
            searchQuery: event.target.value,
            searchResults: queryResult
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
    };

    getSearchIcon = (textColour) => {
        if (this.state.showSearch === true) {
            return (
                <FontAwesomeIcon
                    className={classNames(
                        this.props.classes.icon,
                        this.props.classes.lightText,
                        `text-shadow-double`
                    )}
                    icon={GetIcon(`times`)}
                />
            );
        }
        return (
            <FontAwesomeIcon
                className={classNames(
                    this.props.classes.icon,
                    this.props.classes[textColour],
                    `text-shadow-double`
                )}
                icon={GetIcon(`search`)}
            />
        );
    };

    toggleSearch = () => {
        this.setState({ showSearch: !this.state.showSearch });
    };

    closeSearch = () => {
        this.setState({ showSearch: false });
    };

    handleKeyPress = (event) => {
        if (event.keyCode === 27) {
            //ESC
            event.preventDefault();
            this.closeSearch();
        }
    };

    openSearchWithKeyPress = (keyName, event, handle) => {
        event.preventDefault();

        if (
            (keyName === `alt+s` || keyName === `⌥+s` || keyName === `/`) &&
            this.state.showSearch === false
        ) {
            this.toggleSearch();
        }
    };

    watchClickOutside = (event) => {
        if (!this.node.contains(event.target)) {
            this.closeSearch();
        }
    };

    render() {
        const { classes, lightText } = this.props;
        const textColour = lightText ? `lightText` : ``;
        const { searchResults, searchQuery, showSearch } = this.state;
        const queryResults = searchQuery === '' ? [] : searchResults;
        return (
            <div ref={(node) => (this.node = node)}>
                <div
                    onClick={this.toggleSearch}
                    className={classes.iconWrapper}
                >
                    <span
                        className={classNames(
                            classes.iconText,
                            classes[textColour],
                            `text-shadow`,
                            `montserrat`
                        )}
                    >
                        Search
                    </span>
                    <Tooltip
                        title={`Search (alt+s | ⌥+s or /)`}
                        placement={`bottom`}
                        classes={{
                            popper: classes.tooltipPopper
                        }}
                    >
                        <IconButton className={classes.iconOuter}>
                            {this.getSearchIcon(textColour)}
                        </IconButton>
                    </Tooltip>
                </div>
                <div className={classes.searchWrapper} hidden={!showSearch}>
                    <form onSubmit={this.handleSubmit}>
                        <div>
                            <label
                                htmlFor="search"
                                className={`visuallyhidden`}
                            >
                                Search
                            </label>
                            <input
                                id="search"
                                value={searchQuery}
                                onChange={this.searchData}
                                placeholder="Search for your next quest..."
                                ref={(input) => {
                                    this.searchInput = input;
                                }}
                                className={classNames(
                                    classes.searchInput,
                                    `text-shadow`,
                                    `montserrat`
                                )}
                                autoComplete={`off`}
                                onKeyDown={this.handleKeyPress}
                            />
                            <Hotkeys
                                keyName="/,alt+s,⌥+s"
                                onKeyDown={this.openSearchWithKeyPress.bind(
                                    this
                                )}
                            />
                        </div>
                    </form>
                    <div
                        className={classNames(
                            classes.resultsContainer,
                            `text-shadow`
                        )}
                        hidden={!queryResults.length}
                        onKeyDown={this.handleKeyPress}
                    >
                        <div className={classes.resultsInner}>
                            <div
                                className={classes.resultTypeContainer}
                                hidden={
                                    !queryResults.filter(
                                        (item) => item.type === 'Quest'
                                    ).length > 0
                                }
                            >
                                <h2 className={classes.resultTitle}>Quests</h2>
                                {queryResults
                                    .filter((item) => item.type === 'Quest')
                                    .map((item) => (
                                        <div
                                            key={`result_${item.url}`}
                                            className={classes.result}
                                        >
                                            <Link
                                                href={`/${item.url}`}
                                                className={classes.link}
                                            >
                                                {item.icon && (
                                                    <FontAwesomeIcon
                                                        icon={GetIcon(
                                                            item.icon
                                                        )}
                                                        className={classNames(
                                                            classes.resultIcon,
                                                            `text-shadow`
                                                        )}
                                                    />
                                                )}
                                                <span
                                                    className={
                                                        classes.resultText
                                                    }
                                                >
                                                    {item.name}
                                                </span>
                                            </Link>
                                        </div>
                                    ))}
                            </div>
                            <div
                                className={classes.resultTypeContainer}
                                hidden={
                                    !queryResults.filter(
                                        (item) => item.type === 'Realm'
                                    ).length
                                }
                            >
                                <h2 className={classes.resultTitle}>Realms</h2>
                                {queryResults
                                    .filter((item) => item.type === 'Realm')
                                    .map((item) => (
                                        <div
                                            key={`result_${item.url}`}
                                            className={classes.result}
                                        >
                                            <Link
                                                href={`/${item.url}`}
                                                className={classes.link}
                                            >
                                                <FontAwesomeIcon
                                                    icon={GetIcon(item.icon)}
                                                    className={classNames(
                                                        classes.resultIcon,
                                                        `text-shadow`
                                                    )}
                                                />
                                                <span
                                                    className={
                                                        classes.resultText
                                                    }
                                                >
                                                    {item.name}
                                                </span>
                                            </Link>
                                        </div>
                                    ))}
                            </div>
                            <div
                                className={classes.resultTypeContainer}
                                hidden={
                                    !queryResults.filter(
                                        (item) => item.type === 'Page'
                                    ).length
                                }
                            >
                                <h2 className={classes.resultTitle}>Pages</h2>
                                {queryResults
                                    .filter((item) => item.type === 'Page')
                                    .map((item) => (
                                        <div
                                            key={`result_${item.url}`}
                                            className={classes.result}
                                        >
                                            <Link
                                                href={`/${item.url}`}
                                                className={classes.link}
                                            >
                                                <FontAwesomeIcon
                                                    icon={GetIcon(`file`)}
                                                    className={classNames(
                                                        classes.resultIcon,
                                                        `text-shadow`
                                                    )}
                                                />
                                                <span
                                                    className={
                                                        classes.resultText
                                                    }
                                                >
                                                    {item.name}
                                                </span>
                                            </Link>
                                        </div>
                                    ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Search.propTypes = {
    classes: PropTypes.object.isRequired
};

export default withStyles(styles, { withTheme: true })(Search);
