import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GetIcon from '../utils/getIcon';

const styles = (theme) => ({
    messageWrapper: {
        position: `relative`,
        margin: `25px 0`,
        padding: `10px`,
        borderRadius: `4px`,
        border: `1px solid #000`
    },
    info: {
        backgroundColor: `#e5e5ef`,
        color: `#006bb4`
    },
    tip: {
        backgroundColor: `#e5efe5`,
        color: `#006400`
    },
    warning: {
        backgroundColor: `#fdf0d5`,
        color: `#6f4400`
    },
    error: {
        backgroundColor: `#fae5e5`,
        color: `#e02b27`
    },
    close: {
        cursor: `pointer`,
        position: `absolute`,
        right: 10,
        top: 15
    },
    title: {
        fontWeight: 600,
        color: `inherit`,
        fontSize: `20px`,
        display: `flex`,
        alignItems: `center`,
        margin: 0
    },
    text: {
        color: `#000`
    },
    icon: {
        marginRight: `7px`
    },
    message: {
        margin: `0 5px`,
        fontSize: `14px`,
        '& p': {
            margin: `0 0 4px`
        },
        '& p:first-child': {
            marginTop: 0
        },
        '& p:last-child': {
            marginBottom: `4px`
        },
        '& ul': {
            margin: `0 0 0 -20px`
        },
        '& ul li span,': {
            color: `inherit`,
            fontWeight: 400
        },
        [theme.breakpoints.up('sm')]: {
            fontSize: `16px`,
            margin: `0 10px 0 27px`,
            '& ul': {
                margin: `0 0 0 -40px`
            }
        }
    }
});

class Message extends React.Component {
    state = {
        visible: true
    };

    getIcon = (type) => {
        switch (type) {
            case `tip`:
                return GetIcon(`check-circle`);
            case `warning`:
                return GetIcon(`exclamation-circle`);
            case `error`:
                return GetIcon(`times-circle`);
            default:
                // `info`
                return GetIcon(`info-circle`);
        }
    };

    hide = () => {
        this.setState({
            visible: false
        });
    };

    render() {
        const { classes, type, title, children, closeButton } = this.props;
        return (
            <div
                className={classNames(
                    `message`,
                    classes.messageWrapper,
                    classes[type]
                )}
                hidden={!this.state.visible}
            >
                {closeButton && (
                    <FontAwesomeIcon
                        icon={GetIcon(`times-circle`)}
                        className={classes.close}
                        onClick={this.hide}
                    />
                )}
                <h6 className={classes.title}>
                    <FontAwesomeIcon
                        icon={this.getIcon(type)}
                        className={classes.icon}
                    />
                    <span className={classes.text}>{title}</span>
                </h6>
                <div className={classes.message}>
                    <span>{children}</span>
                </div>
            </div>
        );
    }
}

Message.propTypes = {
    type: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired
};

export default withStyles(styles, { withTheme: true })(Message);
