import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import LinkItem from './link-item';

class AllRealms extends React.Component {
    getRealms = (data) => {
        return data.prismic.realmlist.realms.map((linkedItem) => (
            <LinkItem
                text={RichText.asText(linkedItem.realm.name)}
                urlPath={`/${linkedItem.realm._meta.uid}`}
                key={linkedItem.realm._meta.uid}
            />
        ));
    };

    render() {
        return (
            <>
                <LinkItem
                    text={`View All`}
                    urlPath={`/realms`}
                    bold={true}
                    key={`realms`}
                />
                <StaticQuery
                    query={`${allRealmsQuery}`}
                    render={(data) => <>{this.getRealms(data)}</>}
                />
            </>
        );
    }
}

export default AllRealms;

const allRealmsQuery = graphql`
    query AllRealmsQuery {
        prismic {
            realmlist(uid: "realms", lang: "en-gb") {
                realms {
                    realm {
                        __typename
                        ... on PRISMIC_Realm {
                            name
                            _meta {
                                uid
                            }
                        }
                    }
                }
            }
        }
    }
`;
