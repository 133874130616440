import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import 'highlight.js/styles/androidstudio.css';
import AppBar from '@material-ui/core/AppBar';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
import logo from '../images/logo.png';
import Footer from './footer';
import Menu from './menu';
import Search from './search';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import { fad } from '@fortawesome/pro-duotone-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';

library.add(fas, fad, fab);

const orangeBorderTopHeight = 5;
const orangeBorderBottomHeight = 1;
const appBarHeight = 60;
const logoHeight = 40;
const orangeBorder = `${orangeBorderTopHeight}px solid `;
const contentMarginTop =
    orangeBorderTopHeight + appBarHeight + orangeBorderBottomHeight;
const boxShadow = `0px 2px 4px -1px rgba(50,50,50,0.2), 0px 4px 5px 0px rgba(50,50,50,0.14), 0px 1px 10px 0px rgba(50,50,50,0.12)`;

const styles = (theme) => ({
    headerWrapper: {
        marginBottom: `${contentMarginTop}px`,
        [theme.breakpoints.up(`xl`)]: {
            marginBottom: `${contentMarginTop + 30}px`
        }
    },
    appBar: {
        borderTop: orangeBorder + theme.palette.primary.main,
        backgroundColor: theme.palette.secondary.main,
        transition: theme.transitions.create([`margin`, `width`], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        boxShadow: boxShadow,
        opacity: 0.97
    },
    increaseOpacity: {},
    toolbar: {
        justifyContent: `space-between`,
        minHeight: `${appBarHeight}px`,
        height: `${appBarHeight}px`,
        [theme.breakpoints.up(`sm`)]: {
            padding: `0 6%`
        },
        [theme.breakpoints.up(`lg`)]: {
            padding: `0 7.5%`
        },
        [theme.breakpoints.up(`xl`)]: {
            padding: `0 11%`
        },
        borderTop: `1px solid #000`,
        borderBottom: `1px solid #000`
    },
    logoWrapper: {
        display: `flex`,
        justifyContent: `flex-start`,
        alignItems: `center`,
        marginBottom: `10px`,
        '&:hover': {
            textDecoration: `none`
        }
    },
    logo: {
        height: `${logoHeight}px`,
        paddingRight: `.5em`,
        marginBottom: `5px`,
        [theme.breakpoints.up(`sm`)]: {
            display: `none`
        }
    },
    title: {
        marginTop: `10px`,
        textAlign: `center`,
        fontFamily: `Londrina Solid`,
        fontSize: `34px`,
        color: theme.palette.primary.main,
        textTransform: `uppercase`,
        letterSpacing: `1px`
    },
    titleAlt: {
        marginLeft: `7px`,
        color: `#FFF`
    },
    content: {
        borderRadius: 0,
        boxShadow: `none`,
        backgroundColor: `#FCFCFC`,
        minHeight: `100%`,
        display: `grid`,
        gridTemplateRows: `auto 1fr auto`,
        gridTemplateColumns: `100%`
    },
    main: {
        minHeight: `90vh`
    }
});

class AppShell extends React.Component {
    state = {
        stickyHeaderActive: false
    };

    componentDidMount() {
        window.addEventListener(`scroll`, this.getScrollHeight);
    }

    componentWillUnmount() {
        window.removeEventListener(`scroll`, this.getScrollHeight);
    }

    getScrollHeight = () => {
        if (window.scrollY > 140) {
            this.setState({ stickyHeaderActive: true });
        } else {
            this.setState({ stickyHeaderActive: false });
        }
    };

    render() {
        const { classes, children, noHeader } = this.props;
        const { stickyHeaderActive } = this.state;

        return (
            <>
                {!noHeader && (
                    <div className={classes.headerWrapper}>
                        <AppBar
                            position={stickyHeaderActive ? `fixed` : `absolute`}
                            className={classNames(classes.appBar, {
                                [classes.increaseOpacity]: stickyHeaderActive
                            })}
                        >
                            <Toolbar className={classes.toolbar}>
                                <div>
                                    <Menu lightText={true} />
                                </div>
                                <Link href="/" className={classes.logoWrapper}>
                                    <img
                                        src={logo}
                                        alt="MageQuest Logo"
                                        className={classes.logo}
                                        height={logoHeight}
                                        width="auto"
                                    />
                                    <div
                                        className={classNames(
                                            classes.title,
                                            `text-shadow`
                                        )}
                                    >
                                        Mage
                                        <span className={classes.titleAlt}>
                                            Quest
                                        </span>
                                    </div>
                                </Link>
                                <div>
                                    <Search lightText={true} />
                                </div>
                            </Toolbar>
                        </AppBar>
                    </div>
                )}
                <Paper className={classes.content}>
                    <main className={classes.main}>{children}</main>
                </Paper>
                <Footer />
            </>
        );
    }
}

AppShell.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    children: PropTypes.array.isRequired,
    footerNav: PropTypes.object,
    noHeader: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(AppShell);
