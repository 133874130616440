import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import classNames from 'classnames';
import logo from '../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GetIcon from '../utils/getIcon';

const styles = (theme) => ({
    headerPanel: {},
    metaPanels: {
        width: `100%`,
        display: `flex`,
        alignItems: `flex-end`,
        padding: `10px`,
        [theme.breakpoints.up(`sm`)]: {
            padding: `15px 30px 25px`
        }
    },
    logo: {
        display: `none`,
        [theme.breakpoints.up(`sm`)]: {
            display: `block`
        }
    },
    metaPanelOuter: {
        [theme.breakpoints.up(`sm`)]: {
            marginLeft: `3px`
        },
        width: `100%`
    },
    metaPanel: {
        margin: `10px`
    },
    link: {
        color: theme.palette.secondary.main,
        fontWeight: 400,
        '`&:hover': {
            textDecoration: `none`
        }
    },
    titleIconContainer: {
        display: `flex`,
        alignItems: `center`,
        [theme.breakpoints.up(`sm`)]: {
            height: `37px`
        }
    },
    title: {
        fontSize: `32px`,
        fontWeight: 700,
        textTransform: `uppercase`,
        [theme.breakpoints.up(`sm`)]: {
            marginTop: 6,
            fontSize: `37px`
        },
        lineHeight: 1
    },
    icon: {
        position: `relative`,
        color: theme.palette.primary.main,
        fontSize: `27px`,
        margin: `10px 5px 10px 0`,
        top: -1,
        [theme.breakpoints.up(`sm`)]: {
            top: -2
        }
    },
    breadcrumbs: {
        position: `relative`,
        lineHeight: 0.8,
        fontSize: `14px`,
        fontWeight: 400,
        textTransform: `uppercase`,
        display: `flex`,
        alignItems: `center`,
        width: `100%`,
        overflowX: `scroll`,
        marginTop: 0,
        padding: `4px 0 0`,
        [theme.breakpoints.up(`sm`)]: {
            marginTop: 4,
            marginBottom: 0
        },
        '& ol': {
            flexWrap: `nowrap`
        },
        '& li': {
            marginBottom: 2,
            whiteSpace: `nowrap`,
            fontFamily: `Montserrat`
        },
        '& li:last-child': {
            marginRight: 10
        }
    },
    breadcrumbIcon: {
        color: theme.palette.secondary.main,
        position: `absolute`,
        width: `1em`,
        height: `1em`,
        top: 1
    },
    breadcrumbText: {
        color: `#000`,
        marginLeft: 20
    }
});

class MetaPanel extends React.Component {
    getPageTypeIcon = (pageType) => {
        switch (pageType.toLowerCase()) {
            case `quest`:
                return `map-marked-alt`;
            case `realm`:
                return GetIcon(`fort-awesome`);
            default:
                return null;
        }
    };

    render() {
        const {
            classes,
            title,
            pageType,
            parentTitle,
            parentLink,
            parentPageType,
            icon,
            children
        } = this.props;
        let parent = ``;

        if (parentTitle && parentLink) {
            parent = (
                <Link href={parentLink} className={classes.link}>
                    {parentPageType ? (
                        <>
                            <FontAwesomeIcon
                                className={classes.breadcrumbIcon}
                                icon={this.getPageTypeIcon(parentPageType)}
                            />
                            <span
                                className={classNames(
                                    classes.breadcrumbText,
                                    `montserrat`
                                )}
                            >
                                {parentTitle}
                            </span>
                        </>
                    ) : (
                        parentTitle
                    )}
                </Link>
            );
        }

        return (
            <header className={classes.headerPanel}>
                <div className={classes.metaPanels}>
                    <img
                        src={logo}
                        alt="MageQuest Logo"
                        className={classes.logo}
                        height={90}
                        width="auto"
                    />
                    <div className={classes.metaPanelOuter}>
                        <div className={classes.titleIconContainer}>
                            <h1
                                className={classNames(
                                    classes.metaPanel,
                                    classes.title
                                )}
                            >
                                {title}
                            </h1>
                            {icon && (
                                <FontAwesomeIcon
                                    icon={GetIcon(icon)}
                                    className={classNames(
                                        classes.icon,
                                        `text-shadow-double`
                                    )}
                                />
                            )}
                        </div>
                        <Breadcrumbs
                            arial-label="Breadcrumb"
                            className={`${classes.metaPanel} ${classes.breadcrumbs}`}
                        >
                            <Link href="/" className={classes.link}>
                                Home
                            </Link>
                            {parent}
                            <div className={classes.link}>
                                {pageType ? (
                                    <>
                                        <FontAwesomeIcon
                                            className={classes.breadcrumbIcon}
                                            icon={this.getPageTypeIcon(
                                                pageType
                                            )}
                                        />
                                        <span
                                            className={classNames(
                                                classes.breadcrumbText,
                                                `montserrat`
                                            )}
                                        >
                                            {title}
                                        </span>
                                    </>
                                ) : (
                                    <span className={`montserrat`}>
                                        {title}
                                    </span>
                                )}
                            </div>
                        </Breadcrumbs>
                    </div>
                </div>
                {children}
            </header>
        );
    }
}

MetaPanel.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired
};

export default withStyles(styles, { withTheme: true })(MetaPanel);
