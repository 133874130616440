import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import 'highlight.js/styles/androidstudio.css';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GetIcon from '../utils/getIcon';
import MenuDrawer from './menu-drawer';

const styles = (theme) => ({
    iconWrapper: {
        position: `relative`
    },
    icon: {
        color: theme.palette.secondary.main,
        minWidth: `1em`,
        maxWidth: `1em`
    },
    menuIcon: {
        paddingLeft: `5px`,
        [theme.breakpoints.up(`md`)]: {
            paddingLeft: `12px`
        }
    },
    iconText: {
        display: `none`,
        [theme.breakpoints.up(`md`)]: {
            display: `block`
        },
        position: `absolute`,
        top: 11,
        left: 48,
        fontSize: `22px`,
        fontWeight: 600,
        textTransform: `uppercase`,
        color: theme.palette.secondary.main,
        cursor: `pointer`,
        lineHeight: `1.25em`
    },
    tooltipPopper: {
        display: `none`,
        [theme.breakpoints.up(`md`)]: {
            display: `block`
        }
    },
    lightText: {
        color: `#FFF`
    }
});

class Menu extends React.Component {
    constructor(props) {
        super(props);

        this.menuDrawer = React.createRef();
    }

    handleClick = () => {
        this.menuDrawer.handleDrawerOpen();
    };

    render() {
        const { classes, lightText } = this.props;
        const textColour = lightText ? `lightText` : ``;

        return (
            <>
                <div onClick={this.handleClick} className={classes.iconWrapper}>
                    <Tooltip
                        title={`Menu (alt+m | ⌥+m)`}
                        placement={`bottom`}
                        classes={{
                            popper: classes.tooltipPopper
                        }}
                    >
                        <IconButton
                            aria-label="Open drawer"
                            className={classes.menuIcon}
                        >
                            <FontAwesomeIcon
                                className={classNames(
                                    classes.icon,
                                    classes[textColour],
                                    `text-shadow-double`
                                )}
                                icon={GetIcon(`bars`)}
                            />
                        </IconButton>
                    </Tooltip>
                    <span
                        className={classNames(
                            classes.iconText,
                            classes[textColour],
                            `text-shadow`,
                            `montserrat`
                        )}
                    >
                        {`Menu`}
                    </span>
                </div>
                <MenuDrawer innerRef={(node) => (this.menuDrawer = node)} />
            </>
        );
    }
}

Menu.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
    lightText: PropTypes.bool
};

export default withStyles(styles, { withTheme: true })(Menu);
