const brands = [
    `fort-awesome`,
    `github`,
    `magento`,
    `stack-overflow`,
    `twitter`
];
const regular = [
    `bars`,
    `puzzle-piece`,
    `search`,
    `circle`,
    `dot-circle`,
    `square`,
    `check-square`,
    `chevron-down`,
    `chevron-up`,
    `times`,
    `syringe`,
    `construction`
];

const GetIcon = (icon) => {
    if (typeof icon === `string`) {
        if (brands.includes(icon)) {
            return [`fab`, icon];
        }
        if (regular.includes(icon)) {
            return [`fas`, icon];
        }
        if (
            icon === `devdocs` ||
            icon === `magento devdocs` ||
            icon === `magento userguide`
        ) {
            return GetIcon(`magento`);
        }
        if (icon === `stack overflow`) {
            return GetIcon(`stack-overflow`);
        }
        if (icon === `article`) {
            return GetIcon(`newspaper`);
        }
        if (icon === `document`) {
            return GetIcon(`file-download`);
        }
        if (icon === `other`) {
            return GetIcon(`link`);
        }
        return [`fad`, icon];
    }
    return ``;
};

export default GetIcon;
