import React from 'react';
import PropTypes from 'prop-types';
import withRoot from '../withRoot';
import { withStyles } from '@material-ui/core';
import Layout from '../components/layout';
import SEO from '../components/seo';
import MetaPanel from '../components/meta-panel';
import Link from '@material-ui/core/Link';
import Message from '../components/message';

const styles = (theme) => ({
    content: {
        padding: `0 20px 10px`
    }
});

class ContactSuccessPage extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <Layout>
                <SEO title={`Contact Us - Success`} />
                <MetaPanel title={`Contact Us`} />
                <div className={classes.content}>
                    <Message
                        type={`tip`}
                        title={`Thanks for getting in touch!`}
                    >
                        <span>
                            We'll aim to respond as soon as we can :){` `}
                            <Link href={`/`}>Click here to go back home</Link>.
                        </span>
                    </Message>
                </div>
            </Layout>
        );
    }
}

ContactSuccessPage.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired
};

export default withRoot(
    withStyles(styles, { withTheme: true })(ContactSuccessPage)
);
