import { SheetsRegistry } from 'jss';
import {
    createMuiTheme,
    createGenerateClassName
} from '@material-ui/core/styles';

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            'Montserrat',
            'Raleway',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"'
        ].join(','),
        useNextVariants: true
    },
    palette: {
        primary: {
            main: '#FC9A00'
        },
        secondary: {
            main: '#515252'
        }
    },
    overrides: {
        MuiTooltip: {
            tooltip: {
                backgroundColor: `#515252`,
                textTransform: `uppercase`,
                fontSize: `16px`,
                fontWeight: 600,
                fontFamily: `Raleway`
            }
        }
    }
});

function createPageContext() {
    return {
        theme,
        sheetsManager: new Map(),
        sheetsRegistry: new SheetsRegistry(),
        generateClassName: createGenerateClassName()
    };
}

export default function getPageContext() {
    if (!process.browser) {
        return createPageContext();
    }

    if (!global.__INIT_MATERIAL_UI__) {
        global.__INIT_MATERIAL_UI__ = createPageContext();
    }

    return global.__INIT_MATERIAL_UI__;
}
